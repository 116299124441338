import * as THREE from "three";
import React, { useRef, useEffect } from 'react'
import { useFrame } from "react-three-fiber";
import { Sphere } from "drei";

const LightMain = ({ sound }) => {
  const light = useRef();
  const lightSphere = useRef();
  const lightMesh = useRef();
  const analyser = useRef();

  const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;

  useEffect(
    () => void (analyser.current = new THREE.AudioAnalyser(sound.current, 32))
  );

  useFrame(() => {
    if (analyser.current) {
      const data = analyser.current.getAverageFrequency();
      const unit = (data / 100) * 2;
      light.current.intensity = map(unit, 0, 3, -100, 100);
      lightSphere.current.scale.x = lightSphere.current.scale.y = lightSphere.current.scale.z = map(unit, 0, 3, 1, 6);
      lightSphere.current.material.color.setRGB(map(unit, 0, 3, 0.3, 1), map(unit, 0, 3, 1, 0.5), map(unit, 0, 3, 0.6, 1))
      light.current.color.setRGB(map(unit, 0, 3, 0.3, 1), map(unit, 0, 3, 1, 0.5), map(unit, 0, 3, 0.6, 1))
    }
  });

  return (
    <pointLight ref={light} distance={80} position={[0, 50, 0]}>
      <Sphere ref={lightSphere}>
        <meshBasicMaterial
          attach="material"
          color={lightMesh}
          transparent
        />
      </Sphere>
    </pointLight>
  );
};

export default LightMain;