// Import React
import React from 'react'

import { useLoader } from "react-three-fiber";
import { TextureLoader } from 'three'

// Import local components
import PlaneGeometry from './PlaneGeometry.js'
import gan from './textures/gan.jpg';


const Plane = ({ position, rotation, color }) => {
  const [map] = useLoader(TextureLoader, [gan])
  return (
    <mesh position={position} rotation={rotation}>
      <PlaneGeometry attach="geometry" />
      <meshPhongMaterial attach="material" bumpMap={map} map={map} color={color} />
    </mesh>
  )
};

export default Plane;