import React, { useState } from 'react';

const ChatInput = ({ me, color, socket }) => {
  const [userMessage, setUserMessage] = useState("");

  const sendUserMessage = () => {
    if (userMessage !== '') {
      socket.emit("message", { message: userMessage })
    }
    clearInput()
  }

  const clearInput = () => {
    setUserMessage('')
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendUserMessage()
    }
  }

  return (
    <div className="chat input flex flex-column">
      <div
        className="username"
        style={{ background: `#${color}` }}
      >
        ♦ — {me}
      </div>
      <div className="flex">
        <input
          type="text"
          value={userMessage}
          onChange={(event) => setUserMessage(event.currentTarget.value)}
          onKeyDown={handleKeyDown}
        />
        <button
          style={{ background: `#${color}` }}
          onClick={sendUserMessage}
        >
          ➤
        </button>
      </div>
    </div>
  );
}

export default ChatInput;