import * as THREE from "three";
import React, { useRef, useEffect, useState } from "react";
import { useThree } from "react-three-fiber";

import LightMain from './LightMain.js';

const AudioListener = React.forwardRef((props, ref) => {
  const [listener] = useState(() => new THREE.AudioListener());
  const { camera } = useThree();

  const sound = useRef(new THREE.Audio(listener));

  useEffect(() => {
    sound.current.setMediaElementSource(ref);
    sound.current.setRefDistance(10);
    camera.add(listener);
    return () => camera.remove(listener);
  }, []);

  return (
    <>
      <positionalAudio ref={sound} args={[listener]} />
      <LightMain sound={sound} />
    </>
  );
  
});

export default AudioListener;