import React, { useRef } from 'react';
import { useFrame } from "react-three-fiber";
import { Octahedron, Html } from "drei";

const Orb = ({ id, x, y, z, color }) => {
  const $orb = useRef();

  useFrame(({ clock }) => {
    $orb.current.position.y += Math.sin(clock.getElapsedTime() * 10) / 10
    $orb.current.rotation.y = clock.getElapsedTime()
  })

  return (
    <Octahedron
      ref={$orb}
      position={[x, y, z]}
    >
      <meshPhongMaterial
        attach="material"
        color={`#${color}`}
        emissive={0x000000}
        shininess={100}
        opacity={0.8}
        transparent
      />
      <Html scaleFactor={30} className="name">
        {id}
      </Html>
    </Octahedron>
  );
};

export default Orb;