import React from 'react';

const ChatUsers = ({ users }) => {
  return (
    <div className="chat users">
      All Users:
      <div>
        {users.map((user, index) => (
          <p key={index}>{user.id}</p>
        ))}
      </div>
    </div>
  );
};

export default ChatUsers;