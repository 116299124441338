// Import React
import React from 'react'

// Import local components
import PlaneGeometry from './PlaneGeometry.js'

const PlaneVideo = ({ position }) => {

  const video = document.createElement("video");
  video.loop = true;
  video.autoplay = true;
  video.playsinline = true;
  video.crossOrigin = "anonymous";
  video.muted = true;
  video.setAttribute("playsinline", "");
  video.setAttribute("webkit-playsinline", "");
  video.style = "display: none";
  video.src = '/gan_vid_smallest.mp4';
  video.play();

  return (
    <mesh position={position} >
      <PlaneGeometry attach="geometry" />
      <meshPhongMaterial attach="material" color={0xffffff}>
        <videoTexture attach="map" args={[video]} />
      </meshPhongMaterial>
    </mesh>
  );
};

export default PlaneVideo;