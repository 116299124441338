import React, { useEffect, useRef } from 'react';

const ChatWindow = ({ messages }) => {
  const messagesEndRef = useRef(null)
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
  }
  useEffect(() => { scrollToBottom() }, [messages]);
  return (
    <div className="chat window" style={{ flexGrow: 2 }}>
      <h2>Chat:</h2>
      <div>
        {messages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </div>
      <div ref={messagesEndRef} />
    </div>
  );
}

export default ChatWindow;