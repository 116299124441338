// Import React & SocketIO
import React, { Suspense, useState, useEffect, useRef } from "react";
import socketIOClient from "socket.io-client";

// Import React Three Fiber
import { Canvas } from "react-three-fiber";

// Import drei helpers
import { OrbitControls, Html } from "drei";

// Import App styles
import "./App.css";

// Import local components
import ChatWindow from './components/ChatWindow.js';
import ChatInput from './components/ChatInput.js';
import ChatUsers from './components/ChatUsers.js';
import Plane from './components/Plane.js';
import PlaneVideo from './components/PlaneVideo.js';
import Orb from './components/Orb.js';
import AudioListener from './components/AudioListener.js'

// Websocket Constants
const ENDPOINT = "https://ware360.herokuapp.com/";
const socket = socketIOClient(ENDPOINT);

// Scene Constants
var WIDTH = window.innerWidth;
var HEIGHT = window.innerHeight;

// Camera Settings
var VIEW_ANGLE = 45;
var ASPECT = WIDTH / HEIGHT;
var NEAR = 1;
var FAR = 500;

// Define Planes
const planes = [
  { position: [0, 100, 0], rotation: [Math.PI / 2, 0, 0], color: 0xffffff }, // Top
  { position: [0, 0, 0], rotation: [-Math.PI / 2, 0, 0], color: 0xffffff }, // Bottom
  { position: [0, 50, 50], rotation: [0, Math.PI, 0], color: 0x8a8a8a }, // Front
  { position: [50, 50, 0], rotation: [0, -Math.PI / 2, 0], color: 0x8a8a8a }, // Right
  { position: [-50, 50, 0], rotation: [0, Math.PI / 2, 0], color: 0x8a8a8a }, // Left
]

export default function App() {
  const [entered, setEntered] = useState(false);
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [me, setMe] = useState({});

  useEffect(() => {
    socket.on("message", (data) => {
      setMessages((messages) => [...messages, `${data.id}: ${data.message}`]);
    });
    socket.on("updateUsers", (data) => {
      setUsers(data);
    });
    socket.on("me", (data) => {
      setMe(data);
    });
  }, []);

  const mediaElement = useRef(
    new Audio("https://wareradio.out.airtime.pro/wareradio_a")
  );
  mediaElement.current.crossOrigin = "anonymous";
  
  function enterHandler() {
    setEntered(true)
    mediaElement.current.play();
  }

  return (
    <div className="flex">
      {entered ? (
        <Canvas
          colorManagement
          concurrent
          gl={{ antialias: true, depth: true }}
          camera={{
            fov: VIEW_ANGLE,
            aspect: ASPECT,
            near: NEAR,
            far: FAR,
          }}
          pixelRatio={window.devicePixelRatio}
        >
          <AudioListener ref={mediaElement.current} />
          <fog color={0x111111} attach="fog" near={4} far={300} />
          <ambientLight color={0x454e54} intensity={0.25} />
          <OrbitControls
            target={[me.x, me.y, me.z]}
            enablePan={false}
            maxDistance={200}
          />
          <PlaneVideo position={[0, 50, -50]} />
          {users.map((user, index) => (
            <Orb
              key={index}
              id={user.id}
              x={user.x}
              y={user.y}
              z={user.z}
              color={user.color}
            />
          ))}
          <Suspense fallback={<Html center>Loading</Html>}>
            {planes.map((plane, index) => (
              <Plane
                key={index}
                position={plane.position}
                rotation={plane.rotation}
                color={plane.color}
              />
            ))}
          </Suspense>
        </Canvas>
      ) : (
        <div className="enter" onClick={enterHandler}>
          <h1>FLASHING IMAGES</h1>
          <div class="circle">
            <img src="360.svg" width="80"></img>
          </div>
          <h1>ENTER 360</h1>
        </div>
      )}
      <div className="flex flex-column">
        <div className="flex flex-column" style={{ flexGrow: 2 }}>
          <ChatWindow messages={messages} />
          <ChatInput socket={socket} me={me.id} color={me.color} />
        </div>
        <ChatUsers users={users} />
      </div>
    </div>
  );
}